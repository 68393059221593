import "./academy-grid.css";
import { useRef, useState, createRef, useEffect } from "react";
import { courses } from "../data/academyData";

export function AcademyGrid(props) {

  const [query, setQuery] = useState("");
  const [filteredCourses, setFilteredCourses] = useState(courses);

  useEffect(() => {
    setMatches();
  }, [query]);

  function tableClickHandler(index){
    
    props.setExpandedCourse(index)
    props.closeDrawer(false);
    }

    function setMatches() {
      const lowerCaseQuery = query.toLowerCase();
      const matches = courses
        .map((course, index) => ({ ...course, originalIndex: index }))
        .filter(course =>
          course.name.toLowerCase().includes(lowerCaseQuery) ||
          course.objectives.toLowerCase().includes(lowerCaseQuery)
        );
      setFilteredCourses(matches);
    }
  return (
    <div id="activityTableWrapper">
      <div id="TSearchSection">
        <div
          className="activitySectionHeader"
          style={{ height: "100%", display: "flex", alignItems: "flex-start" }}
        >
          Academy
        </div>
        <div style={{ display: "flex" }}>
          <input
            id="tableSearch"
            type="text"
            placeholder="Search Courses"
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <button
            className="yellowButton"
            style={{ width: "90px" }}
            onClick={() => 
              setMatches()}
          >
            Enter
          </button>
        </div>
      </div>
      <div>
      <div className="academy-container">
        <h1 className="sectionHeader">Courses</h1>
        <div className="course-grid">
          {filteredCourses.map((course) => (
            <div
              key={course.originalIndex}
              className={`course-card`}
              onClick={() =>
                tableClickHandler(course.originalIndex)
              }
            >
                <div className="course-summary">
                  <h3>{course.name}</h3>
                  <img className="card-img" src={course.img}></img>
                  <p>{course.summary}</p>
                </div>
            </div>
          ))}
        </div>
      </div>
      </div>
    </div>
  );
}
