import "./Academy.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../Auth.js";
import { Nav } from "../components/home-page/nav-section";
import { Footer } from "../components/home-page/footer-section";
import AcademyDrawer from "../components/drawers/AcademyDrawer.jsx";
import { courses } from "../data/academyData";

export default function Academy() {
  const { currentUser, authUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [expandedCourse, setExpandedCourse] = useState(null);

  useEffect(() => {
    if (
      !currentUser ||
      authUserData === undefined ||
      authUserData === null ||
      ((authUserData.license === undefined || authUserData.license === "") &&
        authUserData.userType !== "admin")
    ) {
      navigate("/login");
    } else {
      console.log("Welcome to the academy");
    }
  }, [currentUser, authUserData, navigate]);

  return (
    <div>
      <Nav />
      <div style={{ width: "80px" }}>
        <AcademyDrawer setExpandedCourse={setExpandedCourse} />
      </div>
      <div className="courseContainer">
        {expandedCourse !== null ? (
          <div className="expandedCourse">
            <h2>{courses[expandedCourse].name}</h2>
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${courses[expandedCourse].embedId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={courses[expandedCourse].name}
            ></iframe>
            <p><strong>Objectives:</strong> {courses[expandedCourse].objectives}</p>
            {courses[expandedCourse].handout && (
              <a
                href={courses[expandedCourse].handout}
                download
                className="downloadButton"
              >
                Download Handout
              </a>
            )}
          </div>
        ) : (
          <div className="expandedCourse">
            <img src={"\\Academy\\titles\\Online Academy Landing Page.png"}></img>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}