import "./footer-section.css";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { db } from "../../base";
import { doc, setDoc } from "firebase/firestore";

import { validateEmail } from "../../utils/validateEmail";

export const Footer = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const handleEnter = async () => {
    if (!validateEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    await setDoc(doc(db, "subscribers", email), {
      email: email,
      subscribed: true,
    });

    alert("Thank you for subscribing to our mailing list!");
    setEmail("");
  };
  
  // Function to handle smooth scrolling to a section or navigation to another page and scrolling
  const scrollOrLink = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // If the element exists, scroll to it
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      // If the element does not exist, navigate to the home page with the hash and scroll
      window.location.href = `/#${id}`;
    }
  };


  return (
    <div id="outerFooter">
      <div id="footer">
        <div id="megaMenu">
          <div className="menu">
            <button
              style={{ color: "white", textAlign: "left" }}
              className="linkButton"
              onClick={() => navigate("/activity")}
            >
              Play
            </button>
            <button
              style={{ color: "white", textAlign: "left" }}
              className="linkButton"
              onClick={() => navigate("/purchase")}
            >
              Purchase
            </button>
            <button
              style={{ color: "white", textAlign: "left" }}
              className="linkButton"
              onClick={() => scrollOrLink("activityTableWrapper")}
            >
              Activities
            </button>
          </div>
          <div className="menu">
            <button
              style={{ color: "white", textAlign: "left" }}
              className="linkButton"
              onClick={() => scrollOrLink("howToSectionContainer")}
            >
              How To Use
            </button>
            <button
              style={{ color: "white", textAlign: "left" }}
              className="linkButton"
              onClick={() => scrollOrLink("aboutUsSectionContainer")}
            >
              About
            </button>
            <button
              style={{ color: "white", textAlign: "left" }}
              className="linkButton"
              onClick={() => scrollOrLink("faqSectionContainer")}
            >
              FAQ's
            </button>
          </div>
          <div className="menu">
            <button
              style={{ color: "white", textAlign: "left" }}
              className="linkButton"
              onClick={() => navigate("/academy")}
            >
              Academy
            </button>
            <button
              style={{ color: "white", textAlign: "left" }}
              className="linkButton"
              onClick={() => navigate("/account")}
            >
              Account
            </button>
            <button
              style={{ color: "white", textAlign: "left" }}
              className="linkButton"
              onClick={() => navigate("/tutorials")}
            >
              Tutorials
            </button>
            {/* <button style={{color: "white"}} className="linkButton" onClick={(() => navigate("/news"))}>News</button> */}
          </div>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ fontFamily: "NexaBold" }}>
            If you would like to be contacted about new information, please
            enter your email address.
          </div>
          <div>
            <input
              style={{ color: "white" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              id="emailInput"
              placeholder="Email"
            />
            <button onClick={handleEnter} id="enterButton">
              Enter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
