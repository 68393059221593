export const courses = [
    {
      name: "Neurological pathways for reading",
      objectives:
        "Demonstrate understanding of the interaction between coding, language processing and vision in development of specialized neurologic pathway for reading and use this model in clinical assessment of patient with delayed literacy development.",
      summary:
        "Reading does not just happen. We must build a new brain pathway first",
      embedId: "1bqDx8pOhvs",
      img: "\\Academy\\CrocodileAnswer.png",
      handout: "\\Academy\\Tangrams.pdf"
    },
    {
      name: "What goes wrong",
      objectives:
        "5 things children need. Other reasons literacy development can be delayed with emphasis on visual development.",
      summary: "To build this reading pathway, children need 1,2,3,4,5",
      embedId: "DEa0WI30gWE",
      img: "\\Academy\\DinosaurAnswer.png"
    },
    {
      name: "Clinical presentation",
      objectives:
        "Differentiate the main presenting behaviors with persistent poor attention to visual detail, hyperkinesis or disengagement. Describe learning related vision problems commonly seen. Define and differentiate patient behaviour and clinical findings to customize care.",
      summary:
        "Everyone has a stress response. Koalas, Kangaroos and everything in between",
      embedId: "HqjVwBKeP7M",
      img: "\\Academy\\FrilledLizardAnswer.png",
      handout: "\\Academy\\FlashingPictures.pdf"
    },
    {
      name: "Sensory responses of the nervous system",
      objectives: "Corregulation, The role of shame and fear, Nervous system responses",
      summary: "Stress, shame, and fear reduce visual function",
      embedId: "0Y9gyjHnEeI",
      img: "\\Academy\\PenguinAnswer.png"
    },
    {
      name: "Sensory responses of the nervous system Part 2",
      objectives: "Corregulation, The role of shame and fear, Nervous system responses",
      summary: "Stress, shame, and fear reduce visual function",
      embedId: "0Y9gyjHnEeI",
      img: "\\Academy\\PenguinAnswer.png"
    },
    {
      name: "Sensory responses of the nervous system Part 3",
      objectives: "Corregulation, The role of shame and fear, Nervous system responses",
      summary: "Stress, shame, and fear reduce visual function",
      embedId: "0Y9gyjHnEeI",
      img: "\\Academy\\PenguinAnswer.png"
    },
    {
      name: "Sensory responses of the nervous system Part 4",
      objectives: "Corregulation, The role of shame and fear, Nervous system responses",
      summary: "Stress, shame, and fear reduce visual function",
      embedId: "0Y9gyjHnEeI",
      img: "\\Academy\\PenguinAnswer.png"
    },
    {
      name: "Sensory responses of the nervous system Part 5",
      objectives: "Corregulation, The role of shame and fear, Nervous system responses",
      summary: "Stress, shame, and fear reduce visual function",
      embedId: "0Y9gyjHnEeI",
      img: "\\Academy\\PenguinAnswer.png"
    },
  ];